import React, { useState } from "react"
import { Button, Col, Image, Modal, Row } from "react-bootstrap"
import "./style.scss"

import KrzysztofImg from "../../images/instructors/krzysztof.jpg"
import EwaImg from "../../images/instructors/ewa.jpeg"
import GosiaImg from "../../images/instructors/gosia.jpeg"
import AdriannaImg from "../../images/do_100/Adrianna.jpg"
import WiktoriaImg from "../../images/instructors/wiktoria.jpeg"
import JoannaImg from "../../images/do_100/Joanna Markiewicz_2.jpg"
import AleksandraEmmelImg from "../../images/instructors/aleksandra.jpeg"
import IngaImg from "../../images/instructors/inga.jpeg"
import Antonina from "../../images/instructors/antonina.jpeg"
import DominikaImg from "../../images/instructors/dominika.jpeg"
import KotekImg from "../../images/do_100/kotek_intruktorka.jpg"


const EwaDescription = (<p>Założycielka, dyrektor artystyczny i główny choreograf Profil Dance Company.
  <br/>
  <br/>
  Absolwentka kierunku Choreografia i Techniki Tańca na Akademii Muzycznej w Łodzi oraz Uniwersytetu Warszawskiego,
  gdzie ukończyła kierunek Logistyka Mediów. Dyplomowana instruktorka tańca Polskiej Akademii Sportu oraz certyfikowana
  wychowawczyni i kierowniczka obozowowa Instytutu Doskonalenia Kadr Pedagogicznych.
  <br/>
  <br/>
  Specjalizuje się w technikach modern jazz, jazz, contemporary i akrobatyce a także prowadzi zajęcia Baby Dance,
  Flexibility&Control oraz AkroGim. Jako instruktor charakteryzuje się wymaganiami, ambicją oraz pozytywną energią dążąc
  do realizacji wyznaczonych celów. Jej choreografie wyróżniają się oryginalnym stylem, wrażliwością muzyczną,
  estetyczną oraz spójnością artystyczną. Taniec i tworzenie układów stanowią jej pasję od zawsze, a marzenie o
  założeniu własnej szkoły tańca i wychowywaniu młodych pokoleń tancerzy zostało zrealizowane.</p>)

const GosiaDescription = (<p>
    Założycielka i choreograf Profil Dance Company. Instruktor tańca oraz instruktor sportu o specjalności fitness
    Polskiej Akademii Sportu,
    certyfikowany wychowawca Instytutu Doskonalenia Kadr Pedagogicznych. Poza tym, absolwentka Uniwersytetu
    Warszawskiego na kierunku
    Lingwistyka stosowana niemiecko-angielska.<br/><br/>
    Gosia jest nie tylko dobrym instruktorem, ale przede wszystkim pedagogiem, co odziedziczyła w genach po mamie.
    Zawsze zależy jej na tym,
    aby każdy czuł się dobrze na zajęciach, co pozwala młodym tancerzom na niesamowity rozwój. Specjalizuje się w modern
    jazzie i technikach
    pokrewnych oraz akrobatyce. W naszej szkole prowadzi zarówno zajęcia grupowe z tańca, techniki i akrobatyki, jak i
    zajęcia indywidualne.
    Jako instruktor z uśmiechem motywuje, ale też wymaga od uczniów samodyscypliny. Sama jest sumienna i zaangażowana,
    bo twierdzi, że bez
    tych cech ciężko osiągnąć sukces.<br/><br/>
    Swoją przygodę z ruchem rozpoczęła jako 7-latka na zajęciach z akrobatyki, którą trenowała w Pałacu Młodzieży.
    Następnie przez wiele lat,
    jako tancerka Zespołu Tańca Jazzowego Mirage, kształtowała swój warsztat taneczny pod okiem Anny Kepal. Wraz z
    zespołem zdobyła wiele
    nagród na turniejach tanecznych. Wciąż dba o swój rozwój, biorąc udział w zajęciach, obozach treningowych i
    warsztatach tanecznych dla
    dorosłych. Wie, jak ważna jest wszechstronność, dlatego nadal szkoli się w metodyce prowadzenia zajęć, improwizacji
    ruchowej, stretchingu
    oraz treningach funkcjonalnych dla tancerzy.
  </p>

)

const TBA = "Niebawem pojawi się tutaj coś więcej :) ..."
const AleksandraEmmel = "Wieloletnia tancerka z sukcesami na arenie Polskiej i międzynarodowej. Swoją przygodę z tańcem i akrobatyką zaczęła w wieku 4 lat i zdobyła takie osiągnięcia jak: II-Vicemistrzostwo Świata federacji WADF, 7 miejsce na Mistrzostwach Świata IDO, Mistrzostwo Polski federacji WADF i 4 miejsce na Mistrzostwach Polski IDO. Specjalizuje się w stylach tańca contemporary i jazz, ale również rozwija swoje umiejętności w stylach commercial, highheels i hiphop. Szkoliła się u wielu instruktorów z Polski i zagranicy, uczestniczyła w takich programach tanecznych jak Base Camp 360 i Johanson Dance Academy w Londynie, heelsbylala i Hit The Stage by Tomek Prządka. Od 5 lat tancerka zespołu J.TEAM w Egurrola Dance Studio. Uwielbia pracę z dziećmi i przekazywanie im swojej pasji do tańca i akrobatyki, jest kwalifikowanym instruktorem tańca i wychowawcą. Studiuje fizjoterapię na Akademii Wychowania Fizycznego w Warszawie."
const KrzysztofDescription = (
  <p>Trener akrobatyki sportowej i skoków na ścieżce z ogromnym doświadczeniem. Inicjator sekcji AkroGim Profil. W swoim
    życiu pracował zprofesjonalnymi sportowcami oraz prowadził grupy rekreacyjne i przedzawodowe. Ceniony nauczyciel
    wychowania fizycznego, ulubieniec uczniów.
    <br/>
    <br/>
    Od 2014 roku główny trener i opiekun zajęć akrobatycznych AkroGim w Profil Dance oraz instruktor na obozach
    taneczno-akrobatycznych w Chmielnie.
  </p>)
const AsiaDescription = "Instruktor tańca Polskiej Akademii Sportu oraz wychowawca kolonijny. Studentka 5 roku psychologii na Uniwersytecie Kardynała Stefana Wyszyńskiego w Warszawie. Przygodę z tańcem rozpoczęła od tańca towarzyskiego w szkole Anny Głogowskiej i Marcina Wrzesińskiego. Przez dziesięć lat osiągała sukcesy w turniejach klasy ogólnopolskiej w tańcach standardowych i latynoamerykańskich. Następnie zainteresowała się tańcem modern jazz, który pokochała w zespole tanecznym Mirage w Pałacu Młodzieży. Ponadto w swojej historii z tańcem próbowała różnych stylów tanecznych od tańca klasycznego po zouka brazylijskiego. Przygodę z pierwszym tańcem zaczęła od nauczenia układu swojego męża na własne wesele. Łącząc wiedzę z różnorodnych stylów i doświadczenie pracy instruktora tańca potrafi stworzyć piękną choreografię na pierwszy taniec, która zachwyci zarówno rodziców jak i świadków."

const AdriannaDescription = `Certyfikowany instruktor tańca Placówki Kształcenia Ustawicznego. Ukończyła szkolenie animatorów
zabaw sportowych i rekreacyjnych. Ponadto studentka Szkoły Głównej Gospodarstwa Wiejskiego na
kierunku Turystyka i Rekreacja. Uzdolniona tancerka z doświadczeniem scenicznym i turniejowym.
Ma obycie z wieloma stylami tanecznymi również z disco dance oraz hip-hop’em. Przez wiele lat
szkoliła się w pracy z dziećmi i młodzieżą – najpierw jako asystentka na zajęciach tanecznych, teraz
jako samodzielny instruktor. Do pracy podchodzi z uśmiechem i wielkim zaangażowaniem by swoją
wiedzę i umiejętności przekazywać naszym uczestnikom.`

const WiktoriaDescription = `Tancerka od lat dziecięcych, wielokrotnie nagradzana na krajowych oraz międzynarodowych
turniejach tanecznych. Zdobyła Mistrzostwo Polski jeszcze jako nastolatka a następnie rozwijała się w
spektaklach tanecznych tworzonych przez wybitnych choreografów. W ostatnim czasie uczestniczka
Teatru Tańca Jazzgot pod okiem Anety Bułki występowała licznie na deskach teatrów w Polsce.
Uzdolniony instruktor tańca z doświadczeniem w pracy z dziećmi. Pełna energii i uśmiechu do
poszerzania własnych umiejętności.`


const AntoninaDescription = (
  <p>Pedagog i animatorka kultury, studiuje na kierunku Choreografia na Uniwersytecie Muzycznym im. Fryderyka Chopina w
    Warszawie. Dyplomowana instruktorka tańca jazzowego, po kursie Mazowieckiego Instytutu Kultury.
    <br/>
    Na codzień prowadzi zajęcia również w Akademi Musicalowej "Dell Arte" i pracuje przy spektaklach teatralnych w roli
    choreografa.
    <br/>
    Przez 6 lat działała w Zespole Teatralnym SZTOS, w którym udzielała się w roli tancerki, wokalistki, aktorki a także
    kierownika tanecznego - m. in. w musicalu "Fame", "Twisted" oraz oryginalnym "M. kontra S. ...czyli niewieścim okiem
    wieszczów spór".
    <br/>
    Od 15 lat udziela się w Pałacu Młodzieży w Warszawie, zaczynając jako tancerka zespołu NewFolk, a w tym momencie
    działając w roli instruktora społecznego.
    <br/>
    W swojej karierze pracowała zarówno z dziećmi, młodzieżą, dorosłymi jak i seniorami. Bliskie jej techniki i style
    tańca to: jazz, theatre jazz, modern jazz, tap dance oraz folklor.
    <br/>
    <br/>
    Uważa, że tancerz powinien rozwijać się wszechstronnie i tego też stara się uczyć swych podopiecznych. Ważne jest
    dla niej, aby tancerz tańcząc przekazywał historię i emocje, oraz by każdy znalazł w tej formie coś dla siebie.`
  </p>)

const DominikaBlezienDescription = `Tancerz, choreograf. Ukończyła studia licencjackie na Akademii Humanistyczno-Ekonomicznej w Łodzi. Swoją pasję i zamiłowanie do tańca rozpoczęła w wieku 6 lat. Występowała na wielkich scenach i konkursach międzynarodowych, zdobywając wiele nagród. Wielokrotna mistrzyni Polski oraz finalistka mistrzostw Europy i Świata w stylu jazz, modern, show dance w federacji IDO. Pracowała w studio Buffo w Warszawie jako tancerka`


const GabrielaDescription = `Instruktor akrobatyki Sportowej`
const IngaDescription = (<p>
  Instruktorka Akrobatyki, Gimnastyki i Fitnessu oraz trzykrotna Mistrzyni Polski w fitnessie sportowym oraz Mistrzyni
  Europy z 2018 roku oraz sędzia zawodów gimnastycznych.
  <br/>
  <br/>
  Przez 13 lat trenowała akrobatykę w Zespole Akrobatycznym „Pajęczarki” w Radomiu. W 2019 roku wygrała radomską edycję
  konkursu „Co Za Talent” w układzie TRIO.
  <br/>
  <br/>
  Inga prowadzi treningi grupowe oraz indywidualne treningi akrobatyczne przygotowujące do zawodów. Z pasją pracuje z
  dziećmi, łącząc swoje umiejętności z sympatią do młodych adepów sportu.
</p>)
const instructors = [
  {
    name: "Ewa Konecka",
    src: EwaImg,
    description: EwaDescription,
  },
  {
    name: "Gosia Woszczyk",
    src: GosiaImg,
    description: GosiaDescription,
  },
  // {
  //   name: "Adrianna Sowińska",
  //   src: AdriannaImg,
  //   description: AdriannaDescription,
  // },
  {
    name: "Wiktoria Sobocińska",
    src: WiktoriaImg,
    description: WiktoriaDescription,
  },
  {
    name: "Joanna Markiewicz-Wójcik",
    src: JoannaImg,
    description: AsiaDescription,
  },
  {
    name: "Krzysztof Ciszewski",
    src: KrzysztofImg,
    description: KrzysztofDescription,
  },
  {
    name: "Aleksandra Emmel",
    src: AleksandraEmmelImg,
    description: AleksandraEmmel,
  },
  {
    name: "Inga Kobyłecka",
    src: IngaImg,
    description: IngaDescription, // Inga Kobyłecka - opis TBA
    newName: "Inga Kobyłecka", // Dodano nowe imię i nazwisko
  },
  {
    name: "Dominika Blezien",
    src: DominikaImg,
    description: DominikaBlezienDescription, // Opis Dominiki Blezien
  },
  {
    name: "Antonina Grupińska",
    src: Antonina, // Możesz dodać zdjęcie Antoniny, jeśli masz, lub użyć domyślnego
    description: AntoninaDescription, // Opis Antoniny Grupińskiej
  },
  {
    name: "Gabriela Stachowiak",
    src: KotekImg, // Możesz dodać zdjęcie Gabrieli, jeśli masz, lub użyć domyślnego
    description: GabrielaDescription, // Opis Gabrieli Stachowiak - TBA
  },

]

const AboutInstruktorzy = () => (
  <>
    <Row>
      {instructors.map((instructor, index) => (
        <Instruktor key={index} {...instructor} />
      ))}
    </Row>
  </>
)

const Instruktor = (props) => {
  const [modalShow, setModalShow] = useState(false)

  return (
    <>
      <Col
        lg={props.centered ? { span: 6, offset: 3 } : 6}
        className={"instruktor"}
        onClick={() => setModalShow(true)}
      >
        <div className={"instruktorImage"}>
          <Image
            src={props.src}
            className="instruktorImageimage"
            roundedCircle
          />
          <div className="overlay text-center text-brown">+</div>
        </div>
        <h4 className={"text-center pt-3 pb-3"}>{props.name}</h4>
      </Col>
      <InstruktorModal show={modalShow} onHide={() => setModalShow(false)} {...props} />
    </>
  )
}

function InstruktorModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={"modalInstruktor"}
    >
      <Modal.Header closeButton>
        <Image src={props.src} className="modalInstruktorImage" roundedCircle/>
      </Modal.Header>
      <Modal.Body>
        <br/>
        <h4>{props.name}</h4>
        <p className={"text-justify"}>{props.description}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Zamknij</Button>
      </Modal.Footer>
    </Modal>
  )
}


export default AboutInstruktorzy
